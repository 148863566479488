// Vendor
import React from "react";
import { useQuery } from "react-apollo";

// App
import Loader from "../ui/Loader";
import { MISSING_RATES } from "../../common/Queries";
import RateMismatchFactory from "./RateMismatchFactory";

/**
 * Base component for getting & displaying missing rates.
 */
export function RateMismatchComponent() {
  const { loading, error, data } = useQuery(MISSING_RATES);

  function Content() {
    if (loading) return <Loader />;
    if (error) return <p className="text-danger">{error.message}</p>;
    return <RateMismatchFactory missingRates={data.missingRates} />;
  }

  return (
    <div className="mt-5">
      <h3>Missing Rates</h3>
      <Content />
    </div>
  );
}
