// Vendor
import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";

// App
import ProviderCollection from "../../common/ProviderCollection";

function RateMismatchTable(props) {
  const { missingRates, onDelete, onToggleMute, muted } = props;
  return (
    <Table striped borderless className="text-left">
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>Rate</th>
          <th className="Col--shrink">Rate id</th>
          <th>Provider</th>
          <th>Date</th>
          <th>&nbsp;</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody className="border">
        {missingRates.map((rate, i) => (
          <tr key={i}>
            <td>
              <Link to={`/club/${rate.clubId.slug}`}>{rate.clubId.name}</Link>
            </td>
            <td className="Col--shrink">{rate.name}</td>
            <td className="Col--shrink">{rate.rateTypeId}</td>
            <td className="Col--shrink">
              {
                ProviderCollection.find(
                  p => p.key === rate.clubId.teeSheetProvider
                ).label
              }
            </td>
            <td className="Col--shrink">
              {moment(TimestampFromId(rate._id)).format("YYYY-MM-DD HH:mm")}
            </td>
            <td className="Col--shrink">
              <FontAwesomeIcon
                icon="times"
                className="text-danger"
                onClick={() => onDelete(rate._id)}
                style={{ cursor: "pointer" }}
              />
            </td>
            <td className="Col--shrink">
              <FontAwesomeIcon
                icon={muted ? "bell" : "bell-slash"}
                className="text-secondary"
                onClick={() => onToggleMute(rate._id)}
                style={{ cursor: "pointer" }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

/**
 * Extract the timestamp from an ObjectId and return as Date
 * @param {string} id ObjectId string representation
 * @returns {Date} Timestamp
 */
function TimestampFromId(id) {
  return new Date(parseInt(id.substring(0, 8), 16) * 1000);
}

export default RateMismatchTable;
