// Vendor
import { useMutation } from "@apollo/react-hooks";
import React, { useEffect, useState, useMemo } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { withApollo } from "react-apollo";

// App
import Loader from "../ui/Loader";
import ImageIdCard from "../ui/ImageIdCard";
import DynamicTransTable from "../ui/DynamicTransTable/DynamicTransTable";
import { TimedAlert } from "../ui/TimedAlert";
import { START_PAGE, LINKABLE_CLUBS } from "../../common/Queries";
import { UPDATE_START_PAGE } from "../../common/Mutations";
import { GOLF_AREAS } from "../../common/Queries";
import SelectList from "../ui/SelectList";
import { useReducer } from "react";
import startPageReducer from "./startPageReducer";
import { useQuery } from "react-apollo";

export const StartPageContext = React.createContext();

function StartPage(props) {
  function handleSave() {
    // convert into StartPageInput format
    const input = {
      headerImageSlug: state.headerImageSlug,
      golfCourses: state.golfCourses.map(course => course._id),
      golfDestinations: state.golfDestinations.map(dest => dest._id),
      testimonials: state.testimonials,
      golfDestinationPills: state.golfDestinationPills.map(pill => ({
        ...pill,
        paramValue: encodeURI(pill.text)
      }))
    };

    updateStartPage({
      variables: {
        input
      }
    });
  }

  function hideSaveMessage() {
    setFlags({ ...flags, show: false });
  }

  function redirectWarning() {
    if (hasChanged) return "This will discard all unsaved edits. Are you sure?";
  }

  function showSaveMessage(success, message) {
    setFlags({ show: true, success, message });
  }

  function middleware(event) {
    if (!hasChanged) setHasChanged(true);
    dispatch(event);
  }

  // the state to pass down to children, and dispatch method to change it
  const [state, dispatch] = useReducer(startPageReducer, {});

  // memoize so we only trigger reloads of children when the state changes
  const contextValue = useMemo(() => ({ state, dispatch: middleware }), [
    state
  ]);

  //state
  const [hasChanged, setHasChanged] = useState(false);
  const [flags, setFlags] = useState({
    show: false,
    success: true,
    message: ""
  });

  // Query
  const { loading: queryLoading, error: queryError } = useQuery(START_PAGE, {
    onCompleted: data => dispatch({ type: "INIT", payload: data.startPage })
  });

  // Mutation
  const [updateStartPage, { loading: mutationLoading }] = useMutation(
    UPDATE_START_PAGE,
    {
      onCompleted: res => {
        dispatch({ type: "INIT", payload: res.updateStartPage.startPage });
        setHasChanged(false);
        showSaveMessage(true, "Start page successfully saved.");
      },
      onError: () => showSaveMessage(false, "Could not save start page.")
    }
  );

  useEffect(() => {
    window.onbeforeunload = redirectWarning;
  }, []);

  // Guards
  if (queryLoading) return <Loader fullscreen={true} />;
  if (queryError)
    return <p className="text-danger">Error loading start page</p>;

  return (
    <StartPageContext.Provider value={contextValue}>
      <div className="page">
        <h1>Edit Start Page</h1>
        <AvForm
          onValidSubmit={handleSave}
          onInvalidSubmit={() => showSaveMessage(false, "Invalid inputs")}
        >
          <div className="my-5">
            <h3>Header Image</h3>
            <Row>
              <Col md={8} lg={6}>
                <ImageIdCard
                  publicId={state.headerImageSlug}
                  onChange={event =>
                    middleware({
                      type: "HEADER_IMAGE_SLUG",
                      payload: event.value
                    })
                  }
                  onChangeTargetKey="headerImageSlug"
                />
              </Col>
            </Row>
          </div>

          <SelectList
            onChange={value =>
              middleware({ type: "GOLF_COURSES", payload: value })
            }
            selectQuery={LINKABLE_CLUBS}
            listItems={state.golfCourses}
            selectContainerClass="col-6"
            listContainerClass="col-12"
            title={"Golf Courses"}
          />

          <SelectList
            onChange={value =>
              middleware({ type: "GOLF_DESTINATIONS", payload: value })
            }
            selectQuery={GOLF_AREAS}
            listItems={state.golfDestinations}
            selectContainerClass="col-6"
            listContainerClass="col-12"
            title={"Golf Destinations"}
          />

          <div className="my-5">
            <h3>Golf Destination Pills</h3>
            <DynamicTransTable
              onChange={value =>
                middleware({ type: "GOLF_DESTINATION_PILLS", payload: value })
              }
              translationField={{
                key: "text",
                header: "Area"
              }}
              additionalFields={[{ key: "param", header: "Param" }]}
              items={state.golfDestinationPills}
            />
          </div>

          <div className="my-5">
            <h3>Testimonials</h3>
            <DynamicTransTable
              onChange={value =>
                middleware({ type: "TESTIMONIALS", payload: value })
              }
              translationField={{
                key: "testimonial",
                header: "Testimonial"
              }}
              additionalFields={[{ key: "name", header: "Name" }]}
              items={state.testimonials}
            />
          </div>

          <div className="Save-container">
            <div className="Save-container__phantom" />
            <div className="Save-container__main">
              {flags.show && (
                <TimedAlert
                  className="alert border"
                  delay={3000}
                  color={flags.success ? "success" : "danger"}
                  onClose={hideSaveMessage}
                >
                  {flags.message}
                </TimedAlert>
              )}
              <FormGroup>
                <Button
                  size="lg"
                  color={hasChanged ? "secondary" : "gray"}
                  disabled={!hasChanged || mutationLoading}
                  type="submit"
                >
                  Save
                </Button>
              </FormGroup>
            </div>
          </div>
        </AvForm>
      </div>
    </StartPageContext.Provider>
  );
}

export default withApollo(StartPage);
