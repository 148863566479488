import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory/lib/inMemoryCache";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import ApolloClient from "apollo-client/ApolloClient";
import Auth from "./Auth";

const api_url =
  process.env.REACT_APP_MARSHALLS_BACKEND_URL || "http://localhost:4000";

const httpLink = new HttpLink({
  uri: api_url
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Auth.getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const link = ApolloLink.from([
  onError(catchAuthErr),
  authLink.concat(httpLink)
]);

class ApolloClientProvider {
  constructor() {
    this.client = new ApolloClient({
      link,
      cache: new InMemoryCache({
        addTypename: false
      })
    });
  }
}

function catchAuthErr({ graphQLErrors }) {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.extensions.code === "UNAUTHENTICATED") {
        Auth.clearStorage();
      }
    }
  }
}

export default new ApolloClientProvider();
