import React from "react";
import { useState } from "react";

export function RateNumberEdit({
  name,
  value: initialValue,
  validateValue,
  onChange
}) {
  const [value, setValue] = useState(initialValue);
  const [invalid, setInvalid] = useState(false);

  React.useEffect(() => {
    if (initialValue) setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    if (validateValue === "" || isNaN(validateValue)) setInvalid(false);
  }, [validateValue]);

  return (
    <>
      <input
        className={`form-control form-control-sm ${
          invalid ? "is-invalid" : ""
        }`}
        type="number"
        name={name}
        min={0}
        value={value}
        onKeyPress={event => {
          if (event.charCode === 13) onChange(event.target.value);
        }}
        onBlur={event => {
          if (
            event.target.value === "" &&
            validateValue &&
            !isNaN(validateValue)
          ) {
            setInvalid(true);
          } else {
            if (invalid) setInvalid(false);
            onChange(event.target.value);
          }
        }}
        onChange={event => {
          setValue(event.target.value);
        }}
      />
      {invalid && (
        <span className="invalid-feedback">This field is required</span>
      )}
    </>
  );
}

export function RateNumberRead({ ratePrice, field }) {
  let value = (ratePrice && ratePrice[field]) || undefined;
  return value ? value : <span className="text-muted">-</span>;
}
