export const START_PAGE_FIELDS = `
  headerImageSlug
  golfCourses{
    _id
    name
  }
  golfDestinations{
    _id
    name
  }
  golfDestinationPills{
    text
    param
    paramValue
    i18n
  }
  testimonials{
    name
    testimonial
    i18n
  }
`;
