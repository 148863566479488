export const getHours = () => {
  let hours = [];
  for (let i = 0; i < 24; i++) {
    let hour = i < 10 ? `0${i}` : `${i}`;
    hours.push(hour);
  }
  return hours;
};

export const getMinutes = () => {
  let minutes = [];
  for (let i = 0; i < 60; i++) {
    let hour = i < 10 ? `0${i}` : `${i}`;
    minutes.push(hour);
  }
  return minutes;
};
