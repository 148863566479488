// Vendor
import React, { useState } from "react";
import { useMutation } from "react-apollo";

// App
import {
  MISSING_RATE_DELETE,
  MISSING_RATE_TOGGLE_MUTE
} from "../../common/Mutations";
import RateMismatchTable from "./RateMismatchTable";
import RateMismatchFilterInput from "./RateMismatchFilterInput";

/**
 * Handle mutations & filter out the rates to display.
 *
 * Updates a local state var on succesfull mutations to avoid costly refetches of all missing rates.
 * @param { { missingRates: Object[] } } props Takes an array of all relevant [missingRates]
 */
function RateMismatchFactory(props) {
  // Hooks
  const [missingRates, setMissingRates] = useState(props.missingRates);
  const [filter, setFilter] = useState({
    regexFilter: RegExp("", "i"),
    muteFilter: false
  });
  const [toggleMute, { error: muteError }] = useMutation(
    MISSING_RATE_TOGGLE_MUTE,
    {
      onCompleted: data => handleMuteComplete(data)
    }
  );
  const [deleteMissingRate, { error: deleteError }] = useMutation(
    MISSING_RATE_DELETE,
    {
      onCompleted: data => handleDeleteComplete(data)
    }
  );

  // Handlers
  function handleMuteComplete({ missingRateToggleMute: results }) {
    // On successful mutation, update the affected object in [allMissingRates] state var
    results.ok &&
      setMissingRates(
        missingRates.map(rate =>
          rate._id === results.missingRate._id
            ? { ...rate, muted: !rate.muted }
            : rate
        )
      );
  }

  function handleDeleteComplete({ missingRateDelete: results }) {
    // On successful mutation, remove the affected object from [allMissingRates] state var
    results.ok &&
      setMissingRates(
        missingRates.filter(rate => rate._id !== results.missingRate._id)
      );
  }

  function handleToggleMute(id) {
    toggleMute({ variables: { id } });
  }
  function handleDelete(id) {
    deleteMissingRate({ variables: { id } });
  }

  // Content
  if (muteError) return <p className="text-danger">{muteError.message}</p>;
  if (deleteError) return <p className="text-danger">{deleteError.message}</p>;

  let filteredMissingRates = missingRates.filter(
    e =>
      e.muted === filter.muteFilter && e.clubId.name.match(filter.regexFilter)
  );

  return (
    <>
      <RateMismatchFilterInput
        onFilterChange={newFilter => setFilter(newFilter)}
        filter={filter}
      />
      <RateMismatchTable
        missingRates={filteredMissingRates}
        onDelete={handleDelete}
        onToggleMute={handleToggleMute}
        muted={filter.muteFilter}
      />
    </>
  );
}

export default RateMismatchFactory;
