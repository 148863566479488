const CountryCollection = Object.freeze([
  {
    name: "Algeria",
    code: "dz"
  },
  {
    name: "American Samoa",
    code: "as"
  },
  {
    name: "Andorra",
    code: "ad"
  },
  {
    name: "Angola",
    code: "ao"
  },
  {
    name: "Antigua and Barbuda",
    code: "ag"
  },
  {
    name: "Argentina",
    code: "ar"
  },
  {
    name: "Aruba",
    code: "aw"
  },
  {
    name: "Australia",
    code: "au"
  },
  {
    name: "Austria",
    code: "at"
  },
  {
    name: "Azerbaijan",
    code: "az"
  },
  {
    name: "Bahamas",
    code: "bs"
  },
  {
    name: "Bahrain",
    code: "bh"
  },
  {
    name: "Bangladesh",
    code: "bd"
  },
  {
    name: "Barbados",
    code: "bb"
  },
  {
    name: "Belarus",
    code: "by"
  },
  {
    name: "Belgium",
    code: "be"
  },
  {
    name: "Benin",
    code: "bj"
  },
  {
    name: "Bermuda",
    code: "bm"
  },
  {
    name: "Bhutan",
    code: "bt"
  },
  {
    name: "Bolivia",
    code: "bo"
  },
  {
    name: "Bosnia and Herzegovina",
    code: "ba"
  },
  {
    name: "Botswana",
    code: "bw"
  },
  {
    name: "Brazil",
    code: "br"
  },
  {
    name: "Brunei Darussalam",
    code: "bn"
  },
  {
    name: "Bulgaria",
    code: "bg"
  },
  {
    name: "Burkina Faso",
    code: "bf"
  },
  {
    name: "Burundi",
    code: "bi"
  },
  {
    name: "Cabo Verde",
    code: "cv"
  },
  {
    name: "Cambodia",
    code: "kh"
  },
  {
    name: "Cameroon",
    code: "cm"
  },
  {
    name: "Canada",
    code: "ca"
  },
  {
    name: "Cayman Islands",
    code: "ky"
  },
  {
    name: "Chad",
    code: "td"
  },
  {
    name: "Chile",
    code: "cl"
  },
  {
    name: "China",
    code: "cn"
  },
  {
    name: "Colombia",
    code: "co"
  },
  {
    name: "Cook Islands",
    code: "ck"
  },
  {
    name: "Costa Rica",
    code: "cr"
  },
  {
    name: "Cote d'Ivoire",
    code: "ci"
  },
  {
    name: "Croatia",
    code: "hr"
  },
  {
    name: "Cuba",
    code: "cu"
  },
  {
    name: "Curacao",
    code: "cw"
  },
  {
    name: "Cyprus",
    code: "cy"
  },
  {
    name: "Czech Republic",
    code: "cz"
  },
  {
    name: "Democratic Republic of Congo",
    code: "cd"
  },
  {
    name: "Denmark",
    code: "dk"
  },
  {
    name: "Djibouti",
    code: "dj"
  },
  {
    name: "Dominican Republic",
    code: "do"
  },
  {
    name: "Ecuador",
    code: "ec"
  },
  {
    name: "Egypt",
    code: "eg"
  },
  {
    name: "El Salvador",
    code: "sv"
  },
  {
    name: "England",
    code: "gb"
  },
  {
    name: "Equatorial Guinea",
    code: "gq"
  },
  {
    name: "Estonia",
    code: "ee"
  },
  {
    name: "Eswatini",
    code: "sz"
  },
  {
    name: "Ethiopia",
    code: "et"
  },
  {
    name: "Fiji",
    code: "fj"
  },
  {
    name: "Finland",
    code: "fi"
  },
  {
    name: "France",
    code: "fr"
  },
  {
    name: "French Polynesia",
    code: "pf"
  },
  {
    name: "Gabon",
    code: "ga"
  },
  {
    name: "Gambia",
    code: "gm"
  },
  {
    name: "Germany",
    code: "de"
  },
  {
    name: "Ghana",
    code: "gh"
  },
  {
    name: "Greece",
    code: "gr"
  },
  {
    name: "Guadeloupe",
    code: "gp"
  },
  {
    name: "Guam",
    code: "gu"
  },
  {
    name: "Guatemala",
    code: "gt"
  },
  {
    name: "Guernsey",
    code: "gg"
  },
  {
    name: "Guyana",
    code: "gy"
  },
  {
    name: "Honduras",
    code: "hn"
  },
  {
    name: "Hong Kong",
    code: "hk"
  },
  {
    name: "Hungary",
    code: "hu"
  },
  {
    name: "Iceland",
    code: "is"
  },
  {
    name: "India",
    code: "in"
  },
  {
    name: "Indonesia",
    code: "id"
  },
  {
    name: "Ireland",
    code: "ie"
  },
  {
    name: "Isle of Man",
    code: "im"
  },
  {
    name: "Israel",
    code: "il"
  },
  {
    name: "Italy",
    code: "it"
  },
  {
    name: "Jamaica",
    code: "jm"
  },
  {
    name: "Japan",
    code: "jp"
  },
  {
    name: "Jersey",
    code: "je"
  },
  {
    name: "Jordan",
    code: "jo"
  },
  {
    name: "Kazakhstan",
    code: "kz"
  },
  {
    name: "Kenya",
    code: "ke"
  },
  {
    name: "Kuwait",
    code: "kw"
  },
  {
    name: "Kyrgyzstan",
    code: "kg"
  },
  {
    name: "Lao People's Democratic Republic",
    code: "la"
  },
  {
    name: "Latvia",
    code: "lv"
  },
  {
    name: "Lebanon",
    code: "lb"
  },
  {
    name: "Lesotho",
    code: "ls"
  },
  {
    name: "Lithuania",
    code: "lt"
  },
  {
    name: "Luxembourg",
    code: "lu"
  },
  {
    name: "Macao",
    code: "mo"
  },
  {
    name: "Malaysia",
    code: "my"
  },
  {
    name: "Mali",
    code: "ml"
  },
  {
    name: "Malta",
    code: "mt"
  },
  {
    name: "Martinique",
    code: "mq"
  },
  {
    name: "Mauritius",
    code: "mu"
  },
  {
    name: "Mayotte",
    code: "yt"
  },
  {
    name: "Mexico",
    code: "mx"
  },
  {
    name: "Morocco",
    code: "ma"
  },
  {
    name: "Mozambique",
    code: "mz"
  },
  {
    name: "Myanmar",
    code: "mm"
  },
  {
    name: "Namibia",
    code: "na"
  },
  {
    name: "Nepal",
    code: "np"
  },
  {
    name: "Netherlands",
    code: "nl"
  },
  {
    name: "New Caledonia",
    code: "nc"
  },
  {
    name: "New Zealand",
    code: "nz"
  },
  {
    name: "Nicaragua",
    code: "ni"
  },
  {
    name: "Nigeria",
    code: "ng"
  },
  {
    name: "Niue",
    code: "nu"
  },
  {
    name: "Northern Ireland",
    code: "gb"
  },
  {
    name: "Northern Mariana Islands",
    code: "mp"
  },
  {
    name: "Norway",
    code: "no"
  },
  {
    name: "Oman",
    code: "om"
  },
  {
    name: "Pakistan",
    code: "pk"
  },
  {
    name: "Panama",
    code: "pa"
  },
  {
    name: "Papua New Guinea",
    code: "pg"
  },
  {
    name: "Paraguay",
    code: "py"
  },
  {
    name: "Peru",
    code: "pe"
  },
  {
    name: "Philippines",
    code: "ph"
  },
  {
    name: "Poland",
    code: "pl"
  },
  {
    name: "Portugal",
    code: "pt"
  },
  {
    name: "Puerto Rico",
    code: "pr"
  },
  {
    name: "Qatar",
    code: "qa"
  },
  {
    name: "Republic of the Congo",
    code: "cg"
  },
  {
    name: "Reunion",
    code: "re"
  },
  {
    name: "Romania",
    code: "ro"
  },
  {
    name: "Russia",
    code: "ru"
  },
  {
    name: "Saint Kitts and Nevis",
    code: "kn"
  },
  {
    name: "Saint Lucia",
    code: "lc"
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "vc"
  },
  {
    name: "Samoa",
    code: "ws"
  },
  {
    name: "Saudi Arabia",
    code: "sa"
  },
  {
    name: "Scotland",
    code: "gb"
  },
  {
    name: "Senegal",
    code: "sn"
  },
  {
    name: "Serbia",
    code: "rs"
  },
  {
    name: "Seychelles",
    code: "sc"
  },
  {
    name: "Singapore",
    code: "sg"
  },
  {
    name: "Sint Maarten",
    code: "sx"
  },
  {
    name: "Slovakia",
    code: "sk"
  },
  {
    name: "Slovenia",
    code: "si"
  },
  {
    name: "South Africa",
    code: "za"
  },
  {
    name: "South Korea",
    code: "kr"
  },
  {
    name: "Spain",
    code: "es"
  },
  {
    name: "Sri Lanka",
    code: "lk"
  },
  {
    name: "Sudan",
    code: "sd"
  },
  {
    name: "Sweden",
    code: "se"
  },
  {
    name: "Switzerland",
    code: "ch"
  },
  {
    name: "Taiwan",
    code: "tw"
  },
  {
    name: "Thailand",
    code: "th"
  },
  {
    name: "Trinidad and Tobago",
    code: "tt"
  },
  {
    name: "Turkey",
    code: "tr"
  },
  {
    name: "Ukraine",
    code: "ua"
  },
  {
    name: "United Arab Emirates",
    code: "ae"
  },
  {
    name: "United States of America",
    code: "us"
  },
  {
    name: "Uruguay",
    code: "uy"
  },
  {
    name: "Uzbekistan",
    code: "uz"
  },
  {
    name: "Vanuatu",
    code: "vu"
  },
  {
    name: "Venezuela",
    code: "ve"
  },
  {
    name: "Vietnam",
    code: "vn"
  },
  {
    name: "Virgin Islands (U.S.)",
    code: "vi"
  },
  {
    name: "Wales",
    code: "gb"
  },
  {
    name: "Zambia",
    code: "zm"
  },
  {
    name: "Zimbabwe",
    code: "zw"
  },
  {
    name: "Åland Islands",
    code: "ax"
  }
]);

export default CountryCollection;
