const SeasonRatePriceCollection = Object.freeze([
  {
    rateType: "GREENFEE_18",
    name: "Greenfee 18 holes"
  },
  {
    rateType: "ONE_GREENFEE_ONE_BUGGY",
    name: "1 greenfee + buggy"
  },
  {
    rateType: "TWO_GREENFEE_ONE_BUGGY",
    name: "2 greenfees + buggy"
  },
  {
    rateType: "FOUR_GREENFEE_TWO_BUGGY",
    name: "4 greenfees + 2 buggies"
  }
]);

export default SeasonRatePriceCollection;
