const SeasonDayTypeCollection = Object.freeze([
  {
    dayType: "EARLY_BIRD",
    name: "Early bird"
  },
  {
    dayType: "STANDARD",
    name: "Standard"
  },
  {
    dayType: "TWILIGHT",
    name: "Twilight"
  }
]);

export default SeasonDayTypeCollection;
