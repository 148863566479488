// Vendor
import React from "react";
import { Input, Row, Col, ButtonGroup, Button } from "reactstrap";

/**
 * Input field to filter on club names & button group to filter on muted.
 * @param {{onFilterChange: function, filter: { regexFilter: RegExp, muteFilter: boolean}}} props
 */
function RateMismatchFilterInput(props) {
  const { regexFilter, muteFilter } = props.filter;

  function onTextFilterChange(e) {
    props.onFilterChange({
      regexFilter: RegExp(e.target.value, "i"),
      muteFilter
    });
  }

  function onMuteFilterChange(_muteFilter) {
    if (_muteFilter !== muteFilter) {
      props.onFilterChange({ regexFilter, muteFilter: _muteFilter });
    }
  }

  return (
    <Row>
      <Col>
        <Input
          type="text"
          placeholder="Filter on club names"
          onChange={onTextFilterChange}
        />
      </Col>
      <Col>
        <ButtonGroup className="float-right">
          <Button
            onClick={() => onMuteFilterChange(false)}
            color={muteFilter ? "outline-light" : "primary"}
          >
            Active
          </Button>
          <Button
            onClick={() => onMuteFilterChange(true)}
            color={muteFilter ? "primary" : "outline-light"}
          >
            Muted
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
}

export default RateMismatchFilterInput;
