export default function clubCourseReducer(state, action) {
  switch (action.type) {
    case "INIT":
      return action.payload;

    case "AMENITIES":
      return { ...state, amenities: action.payload };
    case "AREAS":
      return { ...state, areas: action.payload };
    case "COURSE_NAME":
      return { ...state, name: action.payload };
    case "ACTIVE":
      return { ...state, active: action.payload };
    case "ADDRESS":
      return { ...state, address: action.payload };
    case "ARCHITECT":
      return { ...state, architect: action.payload };
    case "CANCELLATION_HOURS":
      return { ...state, cancellationHours: action.payload };
    case "CITY":
      return { ...state, city: action.payload };
    case "COUNTRY":
      return { ...state, country: action.payload };
    case "COUNTRY_CODE":
      return { ...state, countryCode: action.payload };
    case "COURSE_TYPE":
      return { ...state, courseType: action.payload };
    case "DESCRIPTION":
      return { ...state, description: action.payload };
    case "HOLES":
      return { ...state, holes: action.payload };
    case "I18N":
      return { ...state, i18n: action.payload };
    case "LOC":
      return { ...state, loc: action.payload };
    case "LOCAL_TIME_ZONE":
      return { ...state, localTimeZone: action.payload };
    case "MEMBERSHIP":
      return { ...state, membership: action.payload };
    case "PAR":
      return { ...state, par: action.payload };
    case "PHONE":
      return { ...state, phone: action.payload };
    case "PLACE_ID":
      return { ...state, placeId: action.payload };
    case "SEASON_CREATE":
      return { ...state, seasonRates: [...state.seasonRates, action.payload] };
    case "SEASON_DELETE":
      return {
        ...state,
        seasonRates: state.seasonRates.filter(s => s._id !== action.payload)
      };
    case "SEASON_UPDATE":
      let seasonRates = [...state.seasonRates];
      let index = seasonRates.findIndex(s => s._id === action.payload._id);
      seasonRates[index] = action.payload;
      return { ...state, seasonRates };
    case "SLUG":
      return { ...state, slug: action.payload };
    case "STATE":
      return { ...state, state: action.payload };
    case "WEBSITE":
      return { ...state, website: action.payload };

    default:
      return state;
  }
}
