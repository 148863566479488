// Vendor
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Table, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// App
import FlagIcon from "./FlagIcon";

class TranslationTable extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    fallbacks: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string
      })
    ),
    i18n: PropTypes.shape({
      "es-ES": PropTypes.object,
      "sv-SE": PropTypes.object,
      "de-DE": PropTypes.object,
      "da-DK": PropTypes.object,
      "fi-FI": PropTypes.object,
      "fr-FR": PropTypes.object,
      "it-IT": PropTypes.object,
      "nl-NL": PropTypes.object,
      "nb-NO": PropTypes.object,
      "ru-RU": PropTypes.object
    })
  };
  static defaultProps = {
    fallbacks: [],
    i18n: {
      "es-ES": {},
      "sv-SE": {},
      "de-DE": {},
      "da-DK": {},
      "fi-FI": {},
      "fr-FR": {},
      "it-IT": {},
      "nl-NL": {},
      "nb-NO": {},
      "ru-RU": {}
    }
  };
  constructor(props) {
    super(props);

    this.state = {
      selectedLang: "",
      currentTexts: [],
      focusedKey: ""
    };

    this.selectLanguage = this.selectLanguage.bind(this);
    this.saveEdits = this.saveEdits.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  selectLanguage(lang) {
    const { fallbacks, i18n } = this.props;
    let currentTexts = [];
    if (lang === "en-GB") {
      currentTexts = fallbacks.map(r => ({ key: r.key, value: r.value }));
    } else {
      for (let row of Object.entries(i18n[lang])) {
        currentTexts.push({ key: row[0], value: row[1] });
      }
    }
    this.setState({
      selectedLang: lang,
      currentTexts,
      focusedKey: fallbacks[0].key
    });
  }

  saveEdits() {
    const { onChange, fallbacks, i18n } = this.props;
    const { selectedLang, currentTexts } = this.state;

    if (selectedLang === "en-GB") {
      onChange({
        key: "fallbacks",
        value: fallbacks.map(v => ({
          ...v,
          value: currentTexts.find(t => t.key === v.key).value
        }))
      });
    } else {
      let i18nCopy = { ...i18n };
      i18nCopy[selectedLang] = { ...i18n[selectedLang] };
      for (let text of currentTexts) {
        i18nCopy[selectedLang][text.key] = text.value;
      }
      onChange({ key: "i18n", value: i18nCopy });
    }
    this.setState({ selectedLang: "" });
  }

  onTextChange(key, value) {
    const { currentTexts } = this.state;
    const keyIndex = currentTexts.findIndex(t => t.key === key);
    this.setState(prevState => ({
      currentTexts: prevState.currentTexts.map((v, i) =>
        i === keyIndex ? { key, value } : v
      )
    }));
  }

  onKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      this.saveEdits();
    } else if (e.key === "Escape") {
      e.preventDefault();
      this.setState({ selectedLang: "" });
    }
  }

  onFocus(key) {
    const { focusedKey } = this.state;
    if (key !== focusedKey) this.setState({ focusedKey: key });
  }

  render() {
    const { fallbacks, i18n } = this.props;
    const { selectedLang, currentTexts, focusedKey } = this.state;

    const TableHeader = () => (
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>
            <FlagIcon code="gb" className="mx-1" />
            EN
          </th>
          {Object.keys(i18n).map((lang, i) => (
            <th key={i}>
              <FlagIcon code={lang.substr(3).toLowerCase()} className="mx-1" />
              {lang.substr(0, 2).toUpperCase()}
            </th>
          ))}
        </tr>
      </thead>
    );

    const InactiveCell = ({ value }) => (
      <td>
        {value > 0 ? (
          <FontAwesomeIcon
            icon="check-circle"
            className="ml-2 mr-1 text-success"
          />
        ) : (
          <FontAwesomeIcon icon="ban" className="ml-2 mr-1 text-danger" />
        )}
      </td>
    );

    const ActiveCell = ({ targetKey }) => (
      <td>
        <input
          autoFocus={targetKey === focusedKey}
          onFocus={() => this.onFocus(targetKey)}
          value={currentTexts.find(t => t.key === targetKey).value}
          onKeyDown={this.onKeyDown}
          onChange={e => this.onTextChange(targetKey, e.target.value)}
          className="pl-2 w-100"
        />
      </td>
    );

    const ButtonCell = ({ lang }) =>
      selectedLang === lang ? (
        <td style={{ minWidth: "140px" }}>
          <Button
            className="d-inline-flex p-0 pr-2 pl-2 mr-2"
            color="primary"
            type="button"
            onClick={this.saveEdits}
          >
            OK
          </Button>
          <Button
            className="d-inline-flex p-0 pr-2 pl-2 btn-outline-light"
            onClick={() => this.setState({ selectedLang: "" })}
            type="button"
          >
            Cancel
          </Button>
        </td>
      ) : (
        <td>
          <Button
            className="p-0"
            color="link"
            type="button"
            onClick={() => this.selectLanguage(lang)}
          >
            Edit
          </Button>
        </td>
      );

    return (
      <Table striped className="border table-responsive">
        <Fragment>
          <TableHeader />
          <tbody>
            {fallbacks.map((row, i) => (
              <tr key={i}>
                <td>{row.label}</td>
                {selectedLang === "en-GB" ? (
                  <ActiveCell targetKey={row.key} />
                ) : (
                  <InactiveCell value={row.value.length} />
                )}
                {Object.keys(i18n).map((lang, i) =>
                  selectedLang === lang ? (
                    <ActiveCell targetKey={row.key} key={i} />
                  ) : (
                    <InactiveCell key={i} value={i18n[lang][row.key].length} />
                  )
                )}
              </tr>
            ))}
            <tr>
              <td>&nbsp;</td>
              <ButtonCell lang="en-GB" />
              {Object.keys(i18n).map((lang, i) => (
                <ButtonCell lang={lang} key={i} />
              ))}
            </tr>
          </tbody>
        </Fragment>
      </Table>
    );
  }
}

export default TranslationTable;
