// Vendor
import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "reactstrap";

// App
import CountryCollection from "../../common/CountryCollection";
import { PHONE_VALIDATOR, URL_VALIDATOR } from "../../common/Validators";
import { InputField, LanguageDropdown } from "./FunctionalComponents";
import FlagIcon from "./FlagIcon";
import PropTypes from "prop-types";

function ContactInfo(props) {
  function handleCountryChange(item) {
    setSelectedCountry(item);
    dispatch({ type: "COUNTRY", payload: item.name });
    dispatch({ type: "COUNTRY_CODE", payload: item.code });
  }

  function getCountryItem(country) {
    return {
      name: country.name,
      code: country.code,
      jsx: (
        <>
          <FlagIcon code={country.code} className="mr-1" />
          <span>{country.name}</span>
        </>
      )
    };
  }

  function toggleCountryOpen() {
    setCountryOpen(!countryOpen);
  }

  const [countryOpen, setCountryOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [countries, setCountries] = useState([]);

  const { state, dispatch } = useContext(props.context);
  const { address, city, country, phone, website } = state;

  useEffect(() => {
    let match = CountryCollection.find(c => c.name === country);
    if (match) setSelectedCountry(getCountryItem(match));
    setCountries(CountryCollection.map(c => getCountryItem(c)));
  }, []);

  return (
    <div className="ContactInfo mt-3">
      {props.showHeader && <h3>Contact info</h3>}
      <Row form>
        <Col md={4}>
          <InputField
            targetKey="address"
            label="Address"
            value={address}
            onChange={event =>
              dispatch({ type: "ADDRESS", payload: event.value })
            }
            validate={{ required: true }}
          />
        </Col>
        <Col md={4}>
          <InputField
            targetKey="city"
            label="City"
            value={city}
            onChange={event => dispatch({ type: "CITY", payload: event.value })}
            validate={{ required: true }}
          />
        </Col>
        <Col md={4}>
          <InputField
            targetKey="state"
            label="State"
            value={state.state}
            onChange={event =>
              dispatch({ type: "STATE", payload: event.value })
            }
            validate={{ required: true }}
          />
        </Col>
        <Col md={4}>
          <LanguageDropdown
            text={country}
            label="Country"
            items={countries}
            selectedItem={selectedCountry}
            onChange={handleCountryChange}
            id="club-country"
            isOpen={countryOpen}
            toggle={toggleCountryOpen}
          />
        </Col>
        <Col md={4}>
          <InputField
            targetKey="phone"
            label="Phone"
            value={phone}
            onChange={event =>
              dispatch({ type: "PHONE", payload: event.value })
            }
            validate={PHONE_VALIDATOR}
            placeholder="Phone: +12 345 67 89"
          />
        </Col>
        {props.showWebsite && (
          <Col md={4}>
            <InputField
              targetKey="website"
              label="Website"
              value={website}
              onChange={event =>
                dispatch({ type: "WEBSITE", payload: event.value })
              }
              validate={URL_VALIDATOR}
            />
          </Col>
        )}
      </Row>
    </div>
  );
}

ContactInfo.propTypes = {
  context: PropTypes.object.isRequired,
  showWebsite: PropTypes.bool
};

ContactInfo.defaultProps = {
  showWebsite: true
};

export default ContactInfo;
