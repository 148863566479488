import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

class _MapComponent extends Component {
  constructor(props) {
    super(props);

    this.getDefaultLocation = this.getDefaultLocation.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.storeRef = this.storeRef.bind(this);

    this.state = {
      center: this.getDefaultLocation()
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.coordinates !== prevProps.coordinates) {
      this.setState({
        center: this.getDefaultLocation()
      });
    }
  }

  getDefaultLocation = () => {
    return {
      lat: this.props.coordinates[0],
      lng: this.props.coordinates[1]
    };
  };

  storeRef = ref => (this.map = ref);

  handleDragEnd = () => {
    if (this.map) {
      let coords = this.map.getCenter();
      this.setState({
        center: {
          lat: coords.lat(),
          lng: coords.lng()
        }
      });
    }
  };

  handleClick(e) {
    const newCoords = [e.latLng.lat(), e.latLng.lng()];
    this.props.onClick({ newCoords, placeId: e.placeId });
  }

  render() {
    const { coordinates } = this.props;

    if (!coordinates) return null;

    const defaultCenter = this.getDefaultLocation();
    const defaultOptions = {
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false
    };

    return (
      <GoogleMap
        defaultOptions={defaultOptions}
        defaultZoom={8}
        defaultCenter={defaultCenter}
        ref={this.storeRef}
        onDragEnd={this.handleDragEnd}
        center={this.state.center}
        onClick={this.handleClick}
      >
        <Marker position={defaultCenter} />
      </GoogleMap>
    );
  }
}

const MapComponent = withRouter(withScriptjs(withGoogleMap(_MapComponent)));

class Map extends Component {
  render() {
    const { coordinates, onClick } = this.props;
    if (!coordinates || !coordinates.length) return null;

    return (
      <MapComponent
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCRwzctN-vXibQPPQDayl0ZSqIaXYPJtZo&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `450px` }} />}
        mapTypeControl={false}
        mapElement={<div style={{ height: `100%` }} />}
        coordinates={coordinates}
        onClick={onClick}
      />
    );
  }
}

export default Map;
