import React from "react";
import DayType from "./DayType";
import { TargetRateIdRead } from "./TargetRateId";
import { RateNumberRead } from "./PriceNumber";
import { DynamicPriceRead } from "./DynamicPrice";

function ReadCells({ ratePrice, dayType }) {
  return (
    <>
      <td className="text-left" style={{ whiteSpace: "nowrap" }}>
        <p className="m-0">
          <DayType dayType={dayType} />
        </p>
      </td>
      <td style={{ whiteSpace: "nowrap" }}>
        <div className="d-block">
          <TargetRateIdRead ratePrice={ratePrice} />
        </div>
      </td>
      <td>
        <div className="d-block">
          <RateNumberRead ratePrice={ratePrice} field="price" />
        </div>
      </td>
      <td>
        <div className="d-block">
          <RateNumberRead ratePrice={ratePrice} field="rackPrice" />
        </div>
      </td>
      <td>
        <div className="d-block">
          <RateNumberRead ratePrice={ratePrice} field="multiplier" />
        </div>
      </td>
      <td>
        <div className="d-block">
          <DynamicPriceRead ratePrice={ratePrice} />
        </div>
      </td>
    </>
  );
}

export default ReadCells;
