// Vendor
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Card, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Common
import debounce from "../../common/debounce";

// App
import ImageIdCard from "../ui/ImageIdCard";
import TranslationTable from "../ui/TranslationTable";

const languagePropType = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string
});
const defaultLanguageValues = { title: "", description: "" };
class Category extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    category: PropTypes.shape({
      imgId: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      i18n: PropTypes.shape({
        "es-ES": languagePropType,
        "sv-SE": languagePropType,
        "de-DE": languagePropType,
        "da-DK": languagePropType,
        "fi-FI": languagePropType,
        "fr-FR": languagePropType,
        "it-IT": languagePropType,
        "nl-NL": languagePropType,
        "nb-NO": languagePropType,
        "ru-RU": languagePropType
      })
    })
  };
  static defaultProps = {
    category: {
      imgId: "",
      title: "",
      description: "",
      i18n: {
        "es-ES": { ...defaultLanguageValues },
        "sv-SE": { ...defaultLanguageValues },
        "de-DE": { ...defaultLanguageValues },
        "da-DK": { ...defaultLanguageValues },
        "fi-FI": { ...defaultLanguageValues },
        "fr-FR": { ...defaultLanguageValues },
        "it-IT": { ...defaultLanguageValues },
        "nl-NL": { ...defaultLanguageValues },
        "nb-NO": { ...defaultLanguageValues },
        "ru-RU": { ...defaultLanguageValues }
      }
    }
  };
  constructor(props) {
    super(props);

    this.onTranslationsChange = this.onTranslationsChange.bind(this);
    this.onImgChange = this.onImgChange.bind(this);
  }

  onTranslationsChange({ key, value }) {
    const { category, onChange } = this.props;
    let categoryCopy = { ...category };

    if (key === "fallbacks") {
      categoryCopy.title = value.find(t => t.key === "title").value;
      categoryCopy.description = value.find(t => t.key === "description").value;
    } else if (key === "i18n") {
      categoryCopy.i18n = value;
    }
    onChange(categoryCopy);
  }

  onImgChange = debounce(({ _, value }) => {
    const { onChange, category } = this.props;
    onChange({ ...category, imgId: value });
  }, 500);

  render() {
    const { category, onDelete } = this.props;

    let fallbacks = [
      { key: "title", label: "Title", value: category.title, multiline: false },
      {
        key: "description",
        label: "Description",
        value: category.description,
        multiline: true
      }
    ];

    return (
      <Card className="border border-top-0 p-4">
        <ImageIdCard
          className="w-50 mb-4"
          publicId={category.imgId}
          onChangeTargetKey="imgId"
          onChange={this.onImgChange}
        />

        <TranslationTable
          onChange={this.onTranslationsChange}
          fallbacks={fallbacks}
          i18n={category.i18n}
        />

        <div className="d-block w-100 p-1">
          <Button
            className="float-right"
            color="danger"
            type="button"
            onClick={onDelete}
          >
            <FontAwesomeIcon icon="trash-alt" className="mr-2" />
            Remove
          </Button>
        </div>
      </Card>
    );
  }
}

export default Category;
