import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup
} from "reactstrap";
import IdGeneratorHelper from "../../../../../common/IdGeneratorHelper";
import { getHours, getMinutes } from "../../../../../common/TimeHelper";
import { SeasonContext } from "../Season";
import RatesHelper from "../../../../../common/RatesHelper";

function SeasonDayTypeSetting(props) {
  const { season } = useContext(SeasonContext);
  const { dayType, scheduleType } = props;
  const isActive = season.active[scheduleType][dayType];

  const rate = RatesHelper.find(season.rates, { dayType, scheduleType });
  const { endDate, startDate } = rate ? rate : season;
  const buggyIncluded = rate ? rate.buggyIncluded === true : false;

  const checkboxActiveID = IdGeneratorHelper.getRandomId();
  const checkboxBuggyID = IdGeneratorHelper.getRandomId();

  let hours = getHours();
  let minutes = getMinutes();

  const TimeDropdown = ({ date, onClick, items, format }) => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle size="sm" color="light" outline>
          {moment(date).format(format)}
        </DropdownToggle>
        <DropdownMenu style={{ maxHeight: 200, overflowY: "scroll" }}>
          {items.map((item, i) => (
            <DropdownItem
              key={i}
              onClick={() => {
                onClick(item);
              }}
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const TimeDropdownGroup = ({
    label,
    date,
    dayType,
    field,
    className = ""
  }) => (
    <div className={`mb-2 d-inline-block ${className}`}>
      <label>{label}</label>
      <InputGroup size="sm">
        <div className="input-group-prepend">
          <TimeDropdown
            date={date}
            items={hours}
            format="HH"
            onClick={value => {
              props.onChange(dayType, {
                key: field,
                value: moment(date)
                  .hours(value)
                  .format()
              });
            }}
          />
        </div>
        <div
          className="input-group-append input-group-prepend position-relative"
          style={{ marginLeft: -2, marginRight: -1, zIndex: 3 }}
        >
          <div className="input-group-text">:</div>
        </div>
        <div className="input-group-append">
          <TimeDropdown
            date={date}
            items={minutes}
            format="mm"
            onClick={value => {
              props.onChange(dayType, {
                key: field,
                value: moment(date)
                  .minutes(value)
                  .format()
              });
            }}
          />
        </div>
      </InputGroup>
    </div>
  );

  return (
    <div className="SeasonDayTypeSetting mb-3">
      <label>{props.label}</label>
      <Card>
        <CardBody className="p-3">
          <div className="checkbox-group">
            <input
              id={checkboxActiveID}
              type="checkbox"
              checked={isActive}
              onChange={event => {
                props.onChange(dayType, {
                  key: "enabled",
                  value: event.target.checked
                });
              }}
            />
            <label htmlFor={checkboxActiveID}>
              <small>Active</small>
            </label>
          </div>
          {isActive && (
            <>
              {dayType !== "STANDARD" && (
                <>
                  <TimeDropdownGroup
                    label="Start time"
                    date={startDate}
                    dayType={dayType}
                    field="startDate"
                    className="mr-2"
                  />
                  <TimeDropdownGroup
                    label="End time"
                    field="endDate"
                    date={endDate}
                    dayType={dayType}
                  />
                </>
              )}

              <div className="checkbox-group">
                <input
                  id={checkboxBuggyID}
                  type="checkbox"
                  checked={buggyIncluded}
                  onChange={event => {
                    props.onChange(dayType, {
                      key: "buggyIncluded",
                      value: event.target.checked
                    });
                  }}
                />
                <label htmlFor={checkboxBuggyID}>
                  <small>Buggy included</small>
                </label>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

SeasonDayTypeSetting.propTypes = {
  dayType: PropTypes.oneOf(["STANDARD", "EARLY_BIRD", "TWILIGHT"]),
  scheduleType: PropTypes.oneOf(["STANDARD", "WEEKEND"]),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default SeasonDayTypeSetting;
