import React from "react";
import SVG from "react-inlinesvg";
import rateIconEarlyBird from "../../../../../../../images/rates/rate-early_bird.svg";
import rateIconStandard from "../../../../../../../images/rates/rate-standard.svg";
import rateIconTwilight from "../../../../../../../images/rates/rate-twilight.svg";
import SeasonDayTypeCollection from "../../../collections/SeasonDayTypeCollection";

function DayType({ dayType }) {
  function getDayTypeIcon(dayType) {
    switch (dayType) {
      case "EARLY_BIRD":
        return rateIconEarlyBird;
      case "TWILIGHT":
        return rateIconTwilight;
      case "STANDARD":
        return rateIconStandard;
      default:
        return null;
    }
  }

  return (
    <>
      <span className="icon-container mr-1">
        <SVG className="icon" src={getDayTypeIcon(dayType)} />
      </span>
      {SeasonDayTypeCollection.find(type => type.dayType === dayType).name}
    </>
  );
}

export default DayType;
