// Vendor
import React, { Component } from "react";
import { Input, Form, ButtonGroup, Button, Label } from "reactstrap";
import PropTypes from "prop-types";

// App
import debounce from "../../common/debounce";

class ClubSearchInputForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      nameFilter: "",
      activeFilter: null
    };

    // Bind handlers
    this.onTextChange = this.onTextChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
  }

  onTextChange = debounce(value => {
    this.setState({ nameFilter: value }, this.submitSearch);
  }, 500);

  handleFilterChange(value) {
    this.setState(
      prevState => ({
        activeFilter: prevState.activeFilter === value ? null : value
      }),
      this.submitSearch
    );
  }

  submitSearch() {
    const { nameFilter, activeFilter } = this.state;
    const filter = {
      name: nameFilter
    };
    if (activeFilter !== null) filter["active"] = activeFilter;

    this.props.handleSubmit(filter);
  }

  render() {
    const { activeFilter } = this.state;

    const FilterButtons = () => {
      const id = "btn-group-active";
      const value = activeFilter;
      return (
        <div className="d-inline-block mr-2 mb-2">
          <Label for={id}>Claimed</Label>
          <ButtonGroup id={id}>
            <Button
              color={`${value === true ? "primary" : "outline-light"}`}
              onClick={() => this.handleFilterChange(true)}
            >
              Yes
            </Button>
            <Button
              color={`${value === false ? "primary" : "outline-light"}`}
              onClick={() => this.handleFilterChange(false)}
            >
              No
            </Button>
          </ButtonGroup>
        </div>
      );
    };

    return (
      <Form
        onSubmit={event => {
          event.preventDefault();
          this.submitSearch();
        }}
      >
        <div className="d-flex flex-wrap align-items-end mt-2">
          <div
            style={{ minWidth: "350px" }}
            className="d-inline-block flex-fill mr-2 mb-2"
          >
            <Input
              placeholder="Club name"
              id="nameLike"
              onChange={e => this.onTextChange(e.target.value)}
            />
          </div>
          <FilterButtons />
        </div>
      </Form>
    );
  }
}

export default ClubSearchInputForm;
