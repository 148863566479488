// Vendor
import React, { useState, useEffect } from "react";
import { Card, CardBody, Button, Popover, PopoverBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropTypes } from "prop-types";

// App
import ContactForm from "./ContactForm";
import IdGeneratorHelper from "../../common/IdGeneratorHelper";

function ContactCard(props) {
  function handleFormClose(contactData) {
    setName(contactData.name);
    setTitle(contactData.title);
    setEmail(contactData.email);
    setShowForm(false);

    props.onChange({
      prev: { name, title, email },
      value: {
        name: contactData.name,
        title: contactData.title,
        email: contactData.email
      }
    });
  }

  function toggleConfirm() {
    setshowConfirm(!showConfirm);
  }

  function deleteContact() {
    toggleConfirm();
    props.onDelete({ name, title, email });
  }

  const [name, setName] = useState(props.contact.name);
  const [title, setTitle] = useState(props.contact.title);
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState(props.contact.email);
  const [showConfirm, setshowConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);

  useEffect(() => {
    setDeleteId(IdGeneratorHelper.getRandomId());
  }, []);

  return (
    <Card
      className="ContactCard d-inline-flex mr-2 mb-2"
      style={{ minWidth: 240 }}
    >
      {showForm && (
        <ContactForm
          contact={{ name, title, email }}
          onClose={handleFormClose}
        />
      )}

      <CardBody className="card-body px-3 py-2 d-flex">
        <FontAwesomeIcon
          icon="user-circle"
          size="2x"
          className="d-block mt-2 mr-3 text-primary"
        />
        <div>
          <p className="card-title mb-0">{name}</p>
          <small className="card-text text-muted font-italic mb-0">
            {title}
          </small>
          <small className="d-block mb-0 text-primary">
            <a href={`"mailto:${email}`}>{email}</a>
          </small>
          <div className="d-flex align-items-center">
            <Button
              className="pl-0 pr-2"
              color="link"
              size="sm"
              onClick={() => setShowForm(true)}
            >
              Edit
            </Button>
            <span>·</span>
            <Button
              className="text-danger pl-2"
              color="link"
              size="sm"
              id={deleteId}
              onClick={toggleConfirm}
            >
              Delete
            </Button>

            {deleteId && (
              <Popover
                isOpen={showConfirm}
                toggle={toggleConfirm}
                placement="bottom"
                target={deleteId}
              >
                <PopoverBody className="p-3">
                  Are you sure?
                  <Button
                    className="ml-2"
                    color="light"
                    outline
                    size="sm"
                    onClick={toggleConfirm}
                  >
                    No
                  </Button>
                  <Button
                    className="ml-2"
                    color="danger"
                    size="sm"
                    onClick={deleteContact}
                  >
                    Yes
                  </Button>
                </PopoverBody>
              </Popover>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

ContactCard.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default ContactCard;
