// vendor
import React, { useState, useEffect, useContext, useReducer } from "react";
import { useQuery, useMutation } from "react-apollo";
import {
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody
} from "reactstrap";

// app
import { CLUB_GET_BY_SLUG } from "../../common/Queries";
import { CLUB_UPDATE } from "../../common/Mutations";
import { LayoutContext } from "../app/Layout";
import Loader from "../ui/Loader";
import NoMatch from "../ui/NoMatch";
import { AvForm } from "availity-reactstrap-validation";
import FormHelper from "../../common/FormHelper";
import ClubGeneralInfo from "./categories/ClubGeneralInfo";
import ClubCourse from "./courses/ClubCourse";
import ClubContactPersonList from "./categories/ClubContactPersonList";
import clubReducer from "./clubReducer";
import ContactInfo from "../ui/ContactInfo";

// context
export const ClubContext = React.createContext(null);

function Club(props) {
  function addCourse() {
    const newCourse = {
      name: "Untitled course",
      slug: "",
      holes: 18,
      par: 72,
      cancellationHours: 48,
      amenities: [],
      address: "",
      city: "",
      state: "",
      country: "",
      countryCode: "",
      phone: "",
      membership: "Public",
      courseType: "Parkway",
      architect: "",
      active: false,
      description: "",
      i18n: {
        "es-ES": { description: "" },
        "sv-SE": { description: "" },
        "de-DE": { description: "" },
        "da-DK": { description: "" },
        "fi-FI": { description: "" },
        "fr-FR": { description: "" },
        "it-IT": { description: "" },
        "nl-NL": { description: "" },
        "nb-NO": { description: "" },
        "ru-RU": { description: "" }
      },
      areas: [],
      placeId: null,
      localTimeZone: "",
      loc: {
        coordinates: [0, 0]
      },
      seasonRates: []
    };
    dispatch({ type: "COURSE_ADD", payload: newCourse });
    setActiveTab(state.courses.length + 2);
  }

  function addDisabled() {
    const { courses } = state;
    if (courses && courses.length) {
      const newCourses = courses.filter(c => c._id === undefined);
      return newCourses && newCourses.length === 1;
    }
    return false;
  }

  function handleSave() {
    const clubId = state._id;
    let input = { ...state };
    delete input.courses;
    delete input._id;

    updateClub({ variables: { input, clubId } });
  }

  function handleSaveCompleted(res) {
    const data = res.updateClub;
    // props.history.replace(`/club/${data.slug}`);
    setEdited(false);
    if (data.ok) {
      dispatch({ type: "INIT", payload: data.club });
      addAlert({ color: "success", message: "Club successfully updated" });
    } else {
      addAlert({ color: "danger", message: "Failed to updated club" });
    }
  }

  function handleSaveError(data) {
    addAlert({ color: "danger", message: "Failed to update club" });
  }

  function middleware(event) {
    if (!edited) setEdited(true);
    dispatch(event);
  }

  function redirectWarning() {
    if (edited) return "This will discard all unsaved edits. Are you sure?";
  }

  // shortcut
  const { slug } = props.match.params;

  // contexts
  const { addAlert } = useContext(LayoutContext);

  // reducer
  const [state, dispatch] = useReducer(clubReducer, null);

  // state
  const [edited, setEdited] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  // queries, mutations
  const { data, loading, error } = useQuery(CLUB_GET_BY_SLUG, {
    variables: { slug }
  });
  const [updateClub, { loading: mutationLoading }] = useMutation(CLUB_UPDATE, {
    onCompleted: handleSaveCompleted,
    onError: handleSaveError
  });

  // effects
  useEffect(() => {
    if (data) dispatch({ type: "INIT", payload: data.getClubBySlug });
  }, [data]);

  useEffect(() => {
    window.onbeforeunload = redirectWarning;
  }, []);

  if (loading) return <Loader fullscreen={true} />;
  if (error) return <NoMatch />;

  if (!state) return null;

  return (
    <ClubContext.Provider value={{ dispatch: middleware, state }}>
      <div className="Club page">
        {mutationLoading && <Loader fullscreen />}
        <h1 className={state._id ? "mb-0" : ""}>{state.name || ""} </h1>
        {state._id && <p className="text-muted mb-4">ID: {state._id}</p>}

        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === 1 ? "active" : ""}
              onClick={() => setActiveTab(1)}
            >
              Club Info
            </NavLink>
          </NavItem>
          {state.courses.map((course, index) => (
            <NavItem key={index}>
              <NavLink
                className={activeTab === index + 2 ? "active" : ""}
                onClick={() => setActiveTab(index + 2)}
              >
                {course.name}
              </NavLink>
            </NavItem>
          ))}
          <NavItem>
            <Button color="link" onClick={addCourse} disabled={addDisabled()}>
              + Add course
            </Button>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1}>
            <Card>
              <CardBody className="px-4">
                <AvForm
                  onValidSubmit={handleSave}
                  onInvalidSubmit={() =>
                    addAlert({ color: "danger", message: "Invalid inputs" })
                  }
                  onKeyPress={FormHelper.preventSubmitOnEnter}
                >
                  <ClubGeneralInfo
                    name={state.name}
                    teeSheetProvider={state.teeSheetProvider}
                    claimed={state.claimed}
                    accountManagerId={
                      (state.accountManager && state.accountManager._id) || null
                    }
                  />
                  <ContactInfo context={ClubContext} />
                  <ClubContactPersonList contacts={state.contacts} />
                  <Button
                    id="btn-save"
                    className="btn-lg mt-4 float-right"
                    type="submit"
                    color={edited ? "secondary" : "gray"}
                    disabled={!edited}
                  >
                    Save
                  </Button>
                </AvForm>
              </CardBody>
            </Card>
          </TabPane>

          {state.courses.map((course, index) => (
            <TabPane tabId={index + 2} key={index}>
              <Card>
                <CardBody>
                  <ClubCourse clubId={state._id} course={course} />
                </CardBody>
              </Card>
            </TabPane>
          ))}
        </TabContent>
      </div>
    </ClubContext.Provider>
  );
}

export default Club;
