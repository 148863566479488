class MembershipCollection {
  items = [
    "Semi-Private",
    "Resort",
    "Public",
    "Private",
    "Military",
    "Municipal",
    "University"
  ];
}

export default new MembershipCollection();
