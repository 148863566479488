// Vendor
import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import moment from "moment";
import ListFactory from "../ui/ListFactory";

class Invoices extends Component {
  static propTypes = {};
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      invoices: [
        {
          id: "JHS12",
          clubName: "Los Naranjos",
          date: moment().format("ll"),
          amount: 12344.2
        },
        {
          id: "JH614",
          clubName: "Lo Romero",
          date: moment()
            .subtract(2, "days")
            .format("ll"),
          amount: 23544.2
        }
      ]
    };
  }

  render() {
    return (
      <div className="Invoices">
        <h1>Invoices</h1>
        <Card>
          <CardBody>
            <ListFactory type="invoices" items={this.state.invoices} />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Invoices;
