import moment from "moment";
import React, { useState, Fragment } from "react";
import { Button, Table, FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import { AvForm } from "availity-reactstrap-validation";

import { SeasonContext } from "../../Season";
import SeasonRatePriceCollection from "../../collections/SeasonRatePriceCollection";
import RatesHelper from "../../../../../../common/RatesHelper";
import EditCells from "./categories/EditCells";
import ReadCells from "./categories/ReadCells";

function SeasonTable(props) {
  function cancelEdit() {
    toggleEdit();
    setEdits(JSON.parse(JSON.stringify(master)));
  }

  function getRatePrice(rates, rateType, dayType) {
    let ratePrices = rates
      .filter(rate => rate.dayType === dayType)
      .map(rate => rate.prices);
    let flattened = [].concat(...ratePrices);
    return flattened.find(price => price.rateType === rateType);
  }

  function handleTableChange(event) {
    const { dayType, rateType, key, value } = event;
    const { scheduleType } = props;

    const ratePrice = getRatePrice(edits, rateType, dayType);
    let rate = RatesHelper.find(edits, { dayType, scheduleType });
    let newEdits = [...edits];

    // No rate exists so we create it
    if (!rate) {
      newEdits.push({
        dayType,
        scheduleType,
        prices: [
          {
            [key]: value,
            rateType
          }
        ],
        buggyIncluded: false,
        startDate: moment(season.startDate).format(),
        endDate: moment(season.endDate).format()
      });
    }
    // A rate exists so we can either edit the current ratePrice or add a new ratePrice
    else {
      let rateIndex = edits.indexOf(rate);
      let ratePriceIndex =
        rateIndex > -1 ? edits[rateIndex].prices.indexOf(ratePrice) : -1;

      // replace rate price
      if (ratePriceIndex > -1) {
        rate.prices[ratePriceIndex] = { ...ratePrice, [key]: value };
      }
      // add rate price
      else {
        rate.prices.push({
          [key]: value,
          rateType
        });
      }
      // replace rate
      newEdits[rateIndex] = rate;
    }

    setEdits(newEdits);
  }

  function handleValidSubmit() {
    toggleEdit();
    dispatch({ type: `SET_${props.scheduleType}_RATES`, payload: edits });
  }

  function toggleEdit() {
    setEditMode(!editMode);
  }

  // Use the context from Season.js
  const { season, dispatch, editMode, setEditMode } = React.useContext(
    SeasonContext
  );

  // State
  const [master, setMaster] = useState(null);
  const [edits, setEdits] = useState(null);

  // save a copy of the rates if the user is editing and click cancel
  React.useEffect(() => {
    const rates = season.rates.filter(
      rate => rate.scheduleType === props.scheduleType
    );
    setMaster(rates);
    setEdits(JSON.parse(JSON.stringify(rates)));
  }, [season.rates]);

  // Filter out which day types are enabled
  const obj = season.active[props.scheduleType];
  const enabled = Object.keys(obj).filter(key => obj[key] === true);

  if (!master) return null;

  return (
    <AvForm
      beforeSubmitValidation={event => event.stopPropagation()}
      className="SeasonTable"
      onValidSubmit={handleValidSubmit}
    >
      <Table responsive className="border-0">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>External IDs</th>
            <th>Sell price</th>
            <th>Rack price</th>
            <th>Multiplier</th>
            <th>Dyn.</th>
          </tr>
        </thead>
        <tbody className="border">
          {SeasonRatePriceCollection.map((price, i) => (
            <Fragment key={i}>
              <tr>
                <td className="bg-light p-2" colSpan="6">
                  {price.name}
                </td>
              </tr>
              {enabled.map((dayType, i) => (
                <tr key={i}>
                  {editMode ? (
                    <EditCells
                      onChange={event => {
                        handleTableChange({
                          rateType: price.rateType,
                          dayType,
                          key: event.key,
                          value: event.value
                        });
                      }}
                      ratePrice={getRatePrice(edits, price.rateType, dayType)}
                      dayType={dayType}
                    />
                  ) : (
                    <ReadCells
                      ratePrice={getRatePrice(master, price.rateType, dayType)}
                      dayType={dayType}
                    />
                  )}
                </tr>
              ))}
            </Fragment>
          ))}
        </tbody>
      </Table>
      <div>
        {!editMode && (
          <Button color="link" onClick={toggleEdit}>
            Edit
          </Button>
        )}
        {editMode && (
          <>
            <FormGroup>
              <Button color="link" className="mr-2" onClick={cancelEdit}>
                Cancel
              </Button>
              <Button color="secondary">OK</Button>
            </FormGroup>
          </>
        )}
      </div>
    </AvForm>
  );
}

SeasonTable.propTypes = {
  sheduleType: PropTypes.oneOf(["STANDARD", "WEEKEND"])
};

export default SeasonTable;
