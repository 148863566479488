import React from "react";
import { useState } from "react";
import { TARGET_RATE_ID_VALIDATOR } from "../../../../../../../common/Validators";
import { Badge } from "reactstrap";

export function TargetRateIdEdit({ targetRateId, name, onChange }) {
  const validator = TARGET_RATE_ID_VALIDATOR;
  const [value, setValue] = useState(targetRateId);
  const [invalid, setInvalid] = useState(false);

  React.useEffect(() => {
    setValue(targetRateId);
  }, [targetRateId]);

  return (
    <>
      <input
        name={name}
        type="text"
        className={`form-control form-control-sm ${
          invalid ? "is-invalid" : ""
        }`}
        onBlur={event => {
          const value = event.target.value;
          let ids = value
            .split(",")
            .filter(id => id !== "")
            .map(num => Number(num));

          if (value) {
            // only update model if valid input
            if (value.match(validator.pattern.value) !== null) {
              setInvalid(false);
              onChange(ids);
            } else setInvalid(true);
          } else setInvalid(false);
        }}
        onKeyPress={event => {
          if (event.charCode === 13) {
            const { value } = event.target;
            let ids = value
              .split(",")
              .filter(id => id !== "")
              .map(num => Number(num));

            if (value && value.match(validator.pattern.value) !== null)
              onChange(ids);
          }
        }}
        onChange={event => {
          setValue(event.target.value);
        }}
        value={value}
      />
      {invalid && (
        <span className="invalid-feedback">
          {validator.pattern.errorMessage}
        </span>
      )}
    </>
  );
}

export function TargetRateIdRead({ ratePrice }) {
  const targetRateId = (ratePrice && ratePrice.targetRateId) || [];
  return targetRateId.map((id, i) => (
    <Badge key={i} color="light" className="mr-1">
      {id}
    </Badge>
  ));
}
