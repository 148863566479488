import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function DynamicPriceEdit({ value, name, onChange }) {
  return (
    <div className="checkbox-group">
      <input
        id={name}
        name={name}
        type="checkbox"
        checked={value}
        onChange={event => {
          onChange(event.target.checked);
        }}
      />
      <label htmlFor={name} className="mb-0"></label>
    </div>
  );
}

export function DynamicPriceRead({ ratePrice }) {
  const dynamic = (ratePrice && ratePrice.useTeeSheetProviderPricing) || false;

  return ratePrice !== undefined ? (
    <FontAwesomeIcon
      icon={dynamic ? "check" : "ban"}
      className={dynamic ? "text-success" : "text-danger"}
    />
  ) : (
    <span className="text-muted">-</span>
  );
}
