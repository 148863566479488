const locales = [
  {
    lang: "en-GB",
    flagIcon: "gb",
    label: "English"
  },
  {
    lang: "es-ES",
    flagIcon: "es",
    label: "Spanish"
  },
  {
    lang: "sv-SE",
    flagIcon: "se",
    label: "Swedish"
  },
  {
    lang: "de-DE",
    flagIcon: "de",
    label: "German"
  },
  {
    lang: "da-DK",
    flagIcon: "dk",
    label: "Danish"
  },
  {
    lang: "fi-FI",
    flagIcon: "fi",
    label: "Finnish"
  },
  {
    lang: "fr-FR",
    flagIcon: "fr",
    label: "French"
  },
  {
    lang: "it-IT",
    flagIcon: "it",
    label: "Italian"
  },
  {
    lang: "nl-NL",
    flagIcon: "nl",
    label: "Dutch"
  },
  {
    lang: "nb-NO",
    flagIcon: "no",
    label: "Norwegian"
  },
  {
    lang: "ru-RU",
    flagIcon: "ru",
    label: "Russian"
  }
];

class LocaleHelper {
  /**
   * Get clone of language object for a given language code.
   * @param {string} lang Language code
   * @returns {{lang:string, flagIcon:string, label:string}|null}  Copy of language object
   */
  static getLocaleObj(lang) {
    const locale = this.getLocales().find(l => l.lang === lang);
    if (!locale) return null;
    return { ...locale };
  }

  // return clone (immutable)
  static getLocales() {
    return locales.map(language => ({ ...language }));
  }
}

export default LocaleHelper;
