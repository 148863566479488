import React, { useState, useContext } from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";

import RatesHelper from "../../../../../common/RatesHelper";
import SeasonDayTypeSetting from "./SeasonDayTypeSetting";
import SeasonTable from "./table/SeasonTable";
import { SeasonContext } from "../Season";

function SeasonSchedule(props) {
  const { scheduleType } = props;

  // use context created in Season.js
  const { season, dispatch } = useContext(SeasonContext);

  const rates = RatesHelper.filter(season.rates, {
    scheduleType
  });

  // State
  const [scheduleEnabled, setScheduleEnabled] = useState(rates.length > 0);

  function getScheduleLabel(scheduleType) {
    switch (scheduleType) {
      case "STANDARD":
        return "Standard rates";
      case "WEEKEND":
        return "Weekend rates";
      default:
        return "Unknown schedule type";
    }
  }

  function onSettingsChangeHandler(dayType, event) {
    const { scheduleType } = props;
    let newSeason = { ...season };

    if (event.key === "enabled") {
      newSeason.active[scheduleType][dayType] = event.value;
      dispatch({ type: "UPDATE", payload: newSeason });
    } else {
      let rates = RatesHelper.filter(newSeason.rates, { scheduleType });
      let rate = RatesHelper.find(rates, { scheduleType, dayType });

      // Create the rate if not exists
      if (!rate) {
        let newRate = {
          dayType,
          scheduleType,
          prices: [],
          startDate: season.startDate,
          endDate: season.endDate
        };
        // override the currently set prop
        newRate[event.key] = event.value;
        rates.push(newRate);
      }
      // edit rate
      else {
        rate[event.key] = event.value;
      }
      dispatch({ type: `SET_${scheduleType}_RATES`, payload: rates });
    }
  }

  return (
    <div className="SeasonSchedule mt-3">
      <div className="checkbox-group">
        <input
          id={`season-schedule-${scheduleType}`}
          type="checkbox"
          checked={scheduleEnabled}
          onChange={event => {
            setScheduleEnabled(event.target.checked);
          }}
        />
        <label htmlFor={`season-schedule-${scheduleType}`}>
          {getScheduleLabel(scheduleType)}
        </label>
      </div>
      <hr />
      {scheduleEnabled && (
        <Row>
          <Col md={3}>
            <SeasonDayTypeSetting
              dayType="STANDARD"
              label="Standard"
              onChange={onSettingsChangeHandler}
              scheduleType={scheduleType}
            />
            <SeasonDayTypeSetting
              dayType="EARLY_BIRD"
              label="Early bird"
              onChange={onSettingsChangeHandler}
              scheduleType={scheduleType}
            />
            <SeasonDayTypeSetting
              dayType="TWILIGHT"
              label="Twilight"
              onChange={onSettingsChangeHandler}
              scheduleType={scheduleType}
            />
          </Col>
          <Col md={9}>
            <SeasonTable scheduleType={scheduleType} />
          </Col>
        </Row>
      )}
    </div>
  );
}

SeasonSchedule.propTypes = {
  scheduleType: PropTypes.oneOf(["STANDARD", "WEEKEND"])
};

export default SeasonSchedule;
