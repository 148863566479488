// Vendor
import React, { Component } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import Users from "../users/Users";
import OrderList from "../orders/OrderList";
import Invoices from "../invoices/Invoices";
import ClubSearch from "../club-search/ClubSearch";
import NoMatch from "../ui/NoMatch";
import Club from "../clubs/Club";
import StyleTest from "../style-test/StyleTest";
import GolfDestinationList from "../golf-destinations/GolfDestinationList";
import GolfDestination from "../golf-destinations/GolfDestination";
import StartPage from "../start-page/StartPage";
import Order from "../orders/Order";

class Routes extends Component {
  static propTypes = {};
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/start-page" component={StartPage} />
        <Route path="/orders" component={OrderList} />
        <Route path="/order/:id" component={Order} />
        <Route path="/clubs" component={ClubSearch} />
        <Route path="/club/:slug" component={Club} />
        <Route path="/club" component={Club} />
        <Route path="/golf-destinations" component={GolfDestinationList} />
        <Route path="/golf-destination/:slug?" component={GolfDestination} />
        {/* <Route path="/course/:clubSlug" component={Course} /> */}
        <Route path="/invoices" component={Invoices} />
        <Route path="/users" component={Users} />
        <Route path="/start" component={StartPage} />
        <Route path="/style-test" component={StyleTest} />
        <Route component={NoMatch} />
      </Switch>
    );
  }
}

export default withRouter(Routes);
