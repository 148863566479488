function foundMatch(filter, rate) {
  let pass = true;
  Object.keys(filter).forEach(key => {
    if (rate[key] === undefined || rate[key] !== filter[key]) pass = false;
  });
  return pass;
}

class RatesHelper {
  static filter(rates, filter) {
    let filtered = rates.filter(rate => foundMatch(filter, rate));
    return filtered.length ? filtered : [];
  }
  static find(rates, filter) {
    return rates.find(rate => foundMatch(filter, rate));
  }
}

export default RatesHelper;
