class FormHelper {
  /**
   * Used to prevent the form from triggering a submit whenever enter key is pressed inside it.
   */
  static preventSubmitOnEnter(e) {
    if (e.target.type !== "textarea" && e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  }
}

export default new FormHelper();
