// Vendor
import React from "react";
import { Mutation } from "react-apollo";
import { Redirect } from "react-router-dom";
import { Modal, Button } from "reactstrap";

// App
import Loader from "./Loader";

/**
 * @param {Object} params
 * @param {boolean} params.isOpen
 * @param {function} params.toggle
 * @param {Object} params.mutation - gql mutation
 * @param {Object} params.variables - Input arguments for mutation
 * @param {Object} params.redirect - [Optional] Redirect arguments
 * @param {function} params.onSuccess - Callback function if redirect is not defined
 * @param {string} params.header - Header text. Default "Delete?"
 */
export default function DeleteModal({
  isOpen,
  toggle,
  mutation,
  variables,
  redirect,
  onSuccess,
  header = "Delete?"
}) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <h2 className="text-center mt-3">{header}</h2>

      <Mutation mutation={mutation}>
        {(mutation_name, { loading, error, data, called }) => {
          if (loading) return <Loader centered />;
          if (error)
            return <ErrorContent message={error.message} toggle={toggle} />;

          if (called) {
            let returnObj = data[Object.keys(data)[0]];
            if (returnObj.ok) {
              if (redirect) return <Redirect to={redirect} />;
              else onSuccess(returnObj);
            } else {
              return (
                <ErrorContent message={returnObj.message} toggle={toggle} />
              );
            }
          }

          return (
            <ModalBody
              toggle={toggle}
              onClick={() => mutation_name({ variables: { ...variables } })}
            />
          );
        }}
      </Mutation>
    </Modal>
  );
}

const ErrorContent = ({ message, toggle }) => (
  <div className="px-3">
    <h4 className="text-center text-danger">Failed to delete</h4>
    <span className="text-center">{message}</span>
    <Button className="my-2" type="button" block size="lg" onClick={toggle}>
      Ok
    </Button>
  </div>
);

const ModalBody = ({ onClick, toggle }) => (
  <div className="p-3">
    <Button color="danger" block size="lg" onClick={onClick}>
      Delete
    </Button>
    <Button className="btn-outline-light mt-2" block size="lg" onClick={toggle}>
      Cancel
    </Button>
  </div>
);
