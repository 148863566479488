// vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState, useContext } from "react";
import { useMutation } from "react-apollo";
import {
  Alert,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Popover,
  PopoverBody,
  UncontrolledDropdown
} from "reactstrap";

// app
import SeasonTypeCollection from "./collections/SeasonTypeCollection";
import SeasonSchedule from "./categories/SeasonSchedule";
import DateRangeFacet from "../../../ui/facet/DateRangeFacet";
import seasonReducer from "./seasonReducer";
import RatesHelper from "../../../../common/RatesHelper";
import { ClubCourseContext } from "../ClubCourse";
import {
  SEASON_RATE_UPDATE,
  SEASON_RATE_CREATE,
  SEASON_RATE_DELETE
} from "../../../../common/Mutations";
import { LayoutContext } from "../../../app/Layout";
import Loader from "../../../ui/Loader";

// context for child components
export const SeasonContext = React.createContext(null);

function Season(props) {
  function getSeasonTypeLabel(type) {
    const item = SeasonTypeCollection.find(item => item.type === type);
    return item ? item.name : "Select a season";
  }

  function handleDateChange(dates) {
    // only update dates when both have changed
    if (dates.startDate && dates.endDate) {
      const startDate = dates.startDate.format();
      const endDate = dates.endDate.format();
      dispatch({ type: "SET_DATES", payload: { startDate, endDate } });
    }
  }

  function handleDelete() {
    deleteSeasonRate({
      variables: { courseId: courseState._id, seasonRateId: season._id }
    });
  }

  function handleSubmit() {
    const seasonRateId = season._id;
    const courseId = courseState._id;

    // strip input from any rate prices that don't have required fields
    let input = {
      ...season,
      rates: season.rates.map(rate => ({
        ...rate,
        prices: rate.prices.filter(
          rp =>
            rp.price !== undefined &&
            !isNaN(rp.price) &&
            rp.rackPrice !== undefined &&
            !isNaN(rp.rackPrice) &&
            rp.multiplier !== undefined &&
            !isNaN(rp.multiplier)
        )
      }))
    };

    // remove manual flags set on object
    delete input.active;

    if (seasonRateId) {
      // id not present on input schema
      delete input._id;

      updateSeasonRate({ variables: { input, courseId, seasonRateId } });
    } else {
      createSeasonRate({ variables: { input, courseId } });
    }
  }

  function handleCreateSuccess(res) {
    toggle();
    courseDispatch({
      type: "SEASON_CREATE",
      payload: res.createSeasonRate.seasonRate
    });
    addAlert({ color: "success", message: "Season successfully created" });
  }

  function handleFail(message) {
    var element = document.getElementById("modal");
    element.scrollIntoView();
    setError(message);
  }

  function handleUpdateSuccess(res) {
    toggle();
    courseDispatch({
      type: "SEASON_UPDATE",
      payload: res.updateSeasonRate.seasonRate
    });
    addAlert({ color: "success", message: "Season successfully updated" });
  }

  function handleDeleteSuccess(res) {
    togglePopover();
    toggle();
    courseDispatch({ type: "SEASON_DELETE", payload: season._id });
    addAlert({ color: "success", message: "Season successfully deleted" });
  }

  function hasRate(rates, dayType, scheduleType) {
    return (
      RatesHelper.find(rates, {
        dayType,
        scheduleType
      }) !== undefined
    );
  }

  function toggle() {
    props.onClose();
  }

  function togglePopover() {
    setPopover(!popoverOpen);
  }

  // state and dispatch method from parents
  const { state: courseState, dispatch: courseDispatch } = useContext(
    ClubCourseContext
  );
  const { addAlert } = useContext(LayoutContext);

  // state and dispatch method for scope and children
  const [season, dispatch] = React.useReducer(seasonReducer, null);

  // State hooks
  const [popoverOpen, setPopover] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(false);

  // mutations
  const [updateSeasonRate, { loading: updateLoading }] = useMutation(
    SEASON_RATE_UPDATE,
    {
      onCompleted: handleUpdateSuccess,
      onError: () => handleFail("Failed to update season rate.")
    }
  );
  const [createSeasonRate, { loading: createLoading }] = useMutation(
    SEASON_RATE_CREATE,
    {
      onCompleted: handleCreateSuccess,
      onError: () => handleFail("Failed to create season rate.")
    }
  );
  const [deleteSeasonRate, { loading: deleteLoading }] = useMutation(
    SEASON_RATE_DELETE,
    {
      onCompleted: handleDeleteSuccess,
      onError: () => handleFail("Failed to delete season rate.")
    }
  );

  // Init state when 'seasons' prop change
  React.useEffect(() => {
    if (props.season) {
      const { rates } = props.season;
      const payload = {
        ...props.season,
        // extend with flags (removed before save)
        active: {
          STANDARD: {
            STANDARD: hasRate(rates, "STANDARD", "STANDARD"),
            EARLY_BIRD: hasRate(rates, "EARLY_BIRD", "STANDARD"),
            TWILIGHT: hasRate(rates, "TWILIGHT", "STANDARD")
          },
          WEEKEND: {
            STANDARD: hasRate(rates, "STANDARD", "WEEKEND"),
            EARLY_BIRD: hasRate(rates, "EARLY_BIRD", "WEEKEND"),
            TWILIGHT: hasRate(rates, "TWILIGHT", "WEEKEND")
          }
        }
      };
      dispatch({ type: "INIT", payload });
    }
  }, [props.season]);

  React.useEffect(() => {
    setError(false);
  }, [props.open]);

  // guard
  if (!season || !season.rates) return null;

  // shortcut
  const { seasonalType } = season;

  return (
    <SeasonContext.Provider value={{ season, dispatch, editMode, setEditMode }}>
      <Modal isOpen={props.open} toggle={toggle} id="modal" className="Season">
        <ModalHeader toggle={toggle}>
          {season._id ? "Edit Season" : "Create New Season"}
        </ModalHeader>
        <ModalBody>
          {(createLoading || updateLoading || deleteLoading) && (
            <Loader fullscreen />
          )}
          {error && <Alert color="danger">{error}</Alert>}
          <div className="d-flex align-items-start">
            <div>
              <label>Season type</label>
              <UncontrolledDropdown className="d-inline-block mr-2">
                <DropdownToggle
                  color={seasonalType ? "primary" : "outline-light"}
                  caret
                >
                  {getSeasonTypeLabel(seasonalType)}
                </DropdownToggle>
                <DropdownMenu>
                  {SeasonTypeCollection.map((item, i) => (
                    <DropdownItem
                      key={i}
                      active={seasonalType === item.type}
                      onClick={() => {
                        dispatch({
                          type: "SET_SEASONAL_TYPE",
                          payload: item.type
                        });
                      }}
                    >
                      {item.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
            <div className="position-relative" style={{ zIndex: 4 }}>
              <label>Season dates</label>
              <DateRangeFacet
                enableDatesOutsideRange={true}
                showCalendarIcon={true}
                small={true}
                startDate={moment(season.startDate)}
                endDate={moment(season.endDate)}
                id="season-span"
                startDateId="season-span-start-date"
                endDateId="season-span-end-date"
                onDatesChange={handleDateChange}
                isOutsideRange={() => false}
              />
            </div>
          </div>
          <SeasonSchedule scheduleType="STANDARD" />
          <SeasonSchedule scheduleType="WEEKEND" />
        </ModalBody>
        <ModalFooter>
          {season._id && (
            <>
              <Button
                color="link"
                className="text-danger"
                onClick={togglePopover}
                id="popover-season"
              >
                <FontAwesomeIcon icon="trash-alt" className="mr-1" />
                Delete season
              </Button>
              <Popover
                className="p-3"
                placement="top"
                style={{ maxWidth: "200px" }}
                isOpen={popoverOpen}
                target="popover-season"
                toggle={togglePopover}
              >
                <PopoverBody>
                  Are you sure?
                  <Button
                    className="ml-2"
                    color="danger"
                    size="sm"
                    onClick={handleDelete}
                  >
                    Yes
                  </Button>
                </PopoverBody>
              </Popover>
              <Button color="light" outline onClick={props.onDuplicate}>
                <FontAwesomeIcon icon="copy" className="mr-1" />
                Duplicate season
              </Button>
            </>
          )}
          <Button color="secondary" onClick={handleSubmit} disabled={editMode}>
            Save season
          </Button>
        </ModalFooter>
      </Modal>
    </SeasonContext.Provider>
  );
}

export const SeasonPropTypes = {
  seasonalType: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      buggyIncluded: PropTypes.bool,
      scheduleType: PropTypes.oneOf(["STANDARD", "WEEKEND"]),
      dayType: PropTypes.oneOf(["STANDARD", "EARLY_BIRD", "TWILIGHT"]),
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      prices: PropTypes.arrayOf(
        PropTypes.shape({
          price: PropTypes.number,
          rackPrice: PropTypes.number,
          modifier: PropTypes.number,
          rateType: PropTypes.oneOf([
            "GREENFEE_18",
            "GREENFEE_18",
            "ONE_GREENFEE_ONE_BUGGY",
            "TWO_GREENFEE_ONE_BUGGY",
            "FOUR_GREENFEE_TWO_BUGGY"
          ]),
          targetRateId: PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          ),
          useTeeSheetPricing: PropTypes.bool
        })
      )
    })
  )
};

Season.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  season: PropTypes.shape(SeasonPropTypes)
};

Season.defaultProps = {
  endDate: moment(),
  startDate: moment()
};

export default Season;
