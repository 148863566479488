// Vendor
import React from "react";
import { AvField } from "availity-reactstrap-validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from "reactstrap";
import { Image } from "cloudinary-react";

// App
import FlagIcon from "./FlagIcon";

/**
 *
 * @param {{onClickHandler: function, areas: [string]}} args
 */
export const ClubConnectedAreasList = ({ onClickHandler, areas }) => {
  if (!areas.length) return null;

  return (
    <div className="ClubAreaItems">
      {areas.map((area, index) => (
        <li
          className="ClubAreaItems-item d-block mb-3 pill pill--active pill--dismissable mr-2"
          key={index}
        >
          {area}
          <button
            className="pill__close"
            type="button"
            onClick={() => onClickHandler(area)}
          >
            x
          </button>
        </li>
      ))}
    </div>
  );
};

/**
 *
 * @param { { onClickHandler: function, cities: Array<{name: string, imgId: string}>} } params
 */
export const ConnectedCityList = ({ onClickHandler, cities }) => {
  if (!cities.length) return null;

  return (
    <div className="ConnectedCityList">
      {cities.map((city, i) => (
        <li
          className="d-block mb-3 pill pill--active pill--dismissable pill--image mr-2"
          key={i}
        >
          <Image
            className="mr-1"
            publicId={city.imgId}
            width="35"
            height="35"
            crop="fill"
            radius="max"
          />
          {city.name}
          <button
            className="pill__close"
            type="button"
            onClick={() => onClickHandler(city)}
          >
            x
          </button>
        </li>
      ))}
    </div>
  );
};

export const SeasonalTypeSelect = ({ value = "NONE", onChange, id = null }) => {
  const seasonalTypes = {
    NONE: "None",
    LOW_SEASON: "Low season",
    MIDDLE_SEASON: "Standard season",
    HIGH_SEASON: "High season"
  };

  return (
    <select
      defaultValue={value}
      onChange={onChange}
      className="SeasonalTypeSelect"
      id={id}
    >
      {Object.entries(seasonalTypes).map((seasonType, i) => (
        <option value={seasonType[0]} key={i}>
          {seasonType[1]}
        </option>
      ))}
    </select>
  );
};

export const TimeOfDayPicker = ({ hour = 0, minute = 0, onChange }) => {
  let hoursArr = [];
  for (let i = 0; i < 24; i++) {
    hoursArr.push(i.toString().padStart(2, "0"));
  }
  let minutesArr = [];
  for (let i = 0; i < 60; i++) {
    minutesArr.push(i.toString().padStart(2, "0"));
  }

  return (
    <div className="TimeOfDayPicker d-flex">
      <select
        className="TimeOfDayPicker__hour"
        value={hour}
        onChange={e => onChange({ hour: e.target.value, minute })}
      >
        {hoursArr.map((hour, index) => (
          <option value={index} key={index}>
            {hour}
          </option>
        ))}
      </select>
      {":"}
      <select
        className="TimeOfDayPicker__minute"
        value={minute}
        onChange={e => onChange({ hour, minute: e.target.value })}
      >
        {minutesArr.map((minute, index) => (
          <option value={index} key={index}>
            {minute}
          </option>
        ))}
      </select>
    </div>
  );
};

/**
 * @prop {function} onChange Callback called with args { key: $targetKey, value: {string} }
 */
export const InputField = ({
  label = "",
  onChange,
  placeholder = "",
  required = true,
  targetKey,
  type = "text",
  validate = null,
  value = "",
  containerClassName = "",
  options
}) => {
  containerClassName = `float-input-group ${containerClassName}`;
  placeholder = placeholder !== "" ? placeholder : label;
  return (
    <AvField
      className="InputField"
      groupAttrs={{
        className: containerClassName
      }}
      id={`input-${targetKey}`}
      label={label}
      labelHidden={value === ""}
      name={`input-${targetKey}`}
      onChange={e =>
        onChange({
          key: targetKey,
          value: type === "number" ? Number(e.target.value) : e.target.value
        })
      }
      placeholder={placeholder}
      required={required}
      type={type}
      value={value}
      validate={validate}
      {...options}
    />
  );
};

export const FancyDropdown = ({
  text = "",
  label = "",
  items = [],
  onChange = () => {},
  id
}) => {
  return (
    <div className="float-input-group form-group">
      <UncontrolledDropdown id={id} className="dropdown-fancy dropdown d-block">
        <DropdownToggle caret className="btn btn-outline-light d-block w-100">
          <span className="float-left">{text}</span>
        </DropdownToggle>
        <DropdownMenu>
          {items.map((item, i) => (
            <DropdownItem value={item} key={i} onClick={() => onChange(item)}>
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export const LanguageDropdown = ({
  label = "",
  items = [],
  selectedItem = { jsx: null },
  onChange = () => {},
  id
}) => {
  return (
    <div className="float-input-group form-group">
      <UncontrolledDropdown id={id} className="dropdown-fancy">
        <DropdownToggle caret className="btn btn-outline-light w-100">
          <span className="float-left text-left overflow-ellipsis">
            {selectedItem.jsx}
          </span>
        </DropdownToggle>
        <DropdownMenu>
          {items.map((item, i) => (
            <DropdownItem value={item} key={i} onClick={() => onChange(item)}>
              {item.jsx}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export const LanguageButton = ({
  flagIcon,
  label,
  onClick,
  selected = false,
  ok = false,
  edited = false
}) => {
  return (
    <button
      className={`d-block mr-1 mb-1 pill pill--button ${
        selected ? " pill--active" : ""
      }`}
      type="button"
      onClick={onClick}
    >
      <FlagIcon code={flagIcon} className="mr-1" />
      {label}
      {ok && (
        <FontAwesomeIcon
          icon="check-circle"
          className="ml-2 mr-1 text-success"
        />
      )}
      {edited && <span style={{ position: "relative", top: "3px" }}>*</span>}
    </button>
  );
};

export const Select = ({
  label,
  value,
  id,
  onChange,
  children,
  style = null
}) => {
  return (
    <div className="float-input-group form-group">
      <label htmlFor={id}>{label}</label>
      <div className="dropdown" id="dropdown">
        <select
          className="d-block btn btn-outline-light dropdown-toggle w-100"
          value={value}
          id={id}
          onChange={onChange}
          style={style}
        >
          {children}
        </select>
      </div>
    </div>
  );
};
