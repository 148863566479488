// Vendor
import React from "react";
import { useQuery } from "react-apollo";

// App
import { ORDER } from "../../common/Queries";
import Loader from "../ui/Loader";

function Order(props) {
  const id = props.match.params.id;
  const { loading, error, data } = useQuery(ORDER, { variables: { id } });

  function content() {
    if (error)
      return (
        <p className="text-error">
          Failed to fetch order{`\n${error.message}`}
        </p>
      );

    if (loading) return <Loader fullscreen />;

    let prettified = JSON.stringify(data, null, 2);
    return <pre>{prettified}</pre>;
  }

  return <div className="page">{content()}</div>;
}

export default Order;
