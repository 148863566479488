const SeasonTypeCollection = Object.freeze([
  {
    type: "LOW_SEASON",
    name: "Low season",
    color: "light"
  },
  {
    type: "MIDDLE_SEASON",
    name: "Middle season",
    color: "primary"
  },
  {
    type: "HIGH_SEASON",
    name: "High season",
    color: "danger"
  }
]);

export default SeasonTypeCollection;
